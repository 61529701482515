import { createApp } from "vue";
import PortalVue from "portal-vue";
import HoneybadgerVue from "@honeybadger-io/vue";

import { router } from "~/router";
import { store } from "~/store";
import { apolloProvider } from "~/vue-apollo";

import { tooltip } from "~/directives/tooltip";
import { toasts } from "~/plugins/toast";
import { filters } from "~/plugins/filters";

import App from "~/App";
import SvgIcon from "~/components/icons/SvgIcon";
import confirm from "~/components/dialogs/Confirm";
import confirmDelete from "~/components/dialogs/ConfirmDelete";
import confirmRestore from "~/components/dialogs/ConfirmRestore";
import confirmDanger from "~/components/dialogs/ConfirmDanger";
import deleteEvent from "~/components/dialogs/DeleteEvent";
import confirmQueuePayChecks from "~/components/dialogs/ConfirmQueuePayChecks";
import confirmExtensionPoolRequest from "~/components/dialogs/ConfirmExtensionPoolRequest";
import getEventContext from "~/components/dialogs/GetEventContext";
import defaultDialog from "~/components/dialogs/Dialog";
import selectAbsenceType from "~/components/dialogs/SelectAbsenceType";
import selectClearingChannel from "~/components/dialogs/SelectClearingChannel";

import { errorHandler } from "~/plugins/errorHandler";
import { modals } from "~/plugins/modals";
import { dialogs } from "~/components/dialogs/plugin";
import * as baseComponents from "~/components/base";
import { config } from "~/utils/honeybadgerConfig";

const app = createApp(App);

app.use(router);
app.use(store);
app.use(apolloProvider);
app.use(toasts);
app.use(filters);
app.use(modals);
app.use(errorHandler);

app.use(dialogs, {
  default: defaultDialog,
  dialogs: {
    confirmDanger,
    confirmDelete,
    confirmQueuePayChecks,
    confirmExtensionPoolRequest,
    confirmRestore,
    confirm,
    selectAbsenceType,
    selectClearingChannel,
    deleteEvent,
    getEventContext,
  },
});

app.use(PortalVue);
app.use(HoneybadgerVue, config);

app.directive("tooltip", tooltip);

app.component("Svgicon", SvgIcon);

Object.entries(baseComponents).forEach(([name, definition]) => app.component(name, definition));

app.mount("#app");
